.aboutContainer {
  display: flex;
  flex-direction: column;
align-items: center;
background-image: linear-gradient(45deg, #00B401 , #04d940c9,#06c03b);
  color: #fff;
  padding: 120px;
}
.aboutContainer h1 {
  font-weight: bold;
}
a {
  text-decoration: none;
}

.button {
  background-color: #fff;
  color: black;
  font-weight: 500;
  font-size: 16px;
}
/* Responsive styles for smaller screens*/
@media screen and (max-width: 768px) {
  .aboutContainer{
    padding: 30px;
    align-items: flex-start;
  }
}