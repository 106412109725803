@import url('https://fonts.cdnfonts.com/css/Inter');

.about-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 20px;
}

.right-side-about{
    display: flex;
    flex-direction: column;
}
.up{
    height: 75px;
}
.down{
    display: flex;
    align-items: flex-end;
}
.image-about{
    height: 300px;
    width: 450px;
    border: none;
    border-radius: 5px;
    box-shadow: -2px 0 30px hsla(237, 71%, 52%, 0.2);
}
.left-side-about{
    border: none;
    width:40%;
}
.paragraph{
    display: flex;
}
.about-paragraph{
    font-family: 'Inter', sans-serif;
    color: black;
    font-size: 20px;
}

@media screen and (max-width: 768px) {

    .about-paragraph{
        font-size: 13px;
    }
    .about-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: -2%;
    }
    .left-side-about{
        border: none;
        width:100%;
    }
    .up{
        display: none;
    }

.image-about{
    height: 200px;
    width: 300px;
}
}