@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.spanned{
    color:#00B401;

}
.vision-container{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    max-width: 96%;
}
.icon-vision{
    width:60px;
    height:60px;
    
}
.this-side:hover{
    cursor: pointer;
    border-color: white;
}

.this-side{

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05),0 6px 20px 0 rgba(0, 0, 0, 0.09);
    border: 0.5px solid #00B401;
    border-radius: 10px;
    padding: 15px;
}
.p-vision{
    font-size: 21px;
    font-weight: 500;
}
.h-vision{
    font-size: 18px;
    color: #00B401;
}
.vision{
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05),0 6px 20px 0 rgba(0, 0, 0, 0.09);
    border: 1px solid white;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    text-align: start;
    column-gap: 10px;
    padding:20px;
    font-weight: 500;
}
.vision:hover{
    cursor: pointer;
    border-color: #00B401;
    transform: translate(2px,-2px);
}
@media screen and (max-width: 768px){
    .vision-container{
        flex-direction: column;
        row-gap: 20px;
    }
    .vision{
        padding: 10px;
    }
    .icon-vision{
        width:32px;
        height:32px;
    }
    .p-vision{
        font-size: 14px;
        font-weight: 500;
    }
    .h-vision{
        font-size: 16px;
        color: #00B401;
    }
    .this-side{
        padding: 8px;
    }
}