.content-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 20px;
  }
  
  .content-item {
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  .content-item img {
   max-width: 100%;
    height: auto;
  }
  .image-container {
    display: none; /* Hide images by default */
  }
  
  .content-item:hover {
    transform: translateY(-5px);
  }
  
  .content-item h2 {
    font-size: 24px;
    font-weight: 400;
    position: relative;
    display: inline-block;
  }
  .activities-title {
    font-size:50px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #00B401;
  }
  .content-item p, .activities-paragraph{
    margin-top: 15px;
    text-align: left;
    font-size: 18px;
    font-weight: 300;
  }
  .content-item h2::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 2px;
  }
  .content-item .green-line {
    width: 100%;
    height: 2px;
    background-color: #00B401;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    .activities-title {
        font-size: 30px;
        font-weight: 300;
        text-align: left;
        color: #00B401;
        text-decoration: #00B401;
      }
      .content-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 20px;
      }

  }
  @media (min-width: 768px) {
    .image-container {
      display: block; 
    }
  }