.member-container{
display: flex;
flex-direction: row;
column-gap: 20px;
margin-left: 40px;
margin-right: 40px;
}
.member-item{
    text-overflow: none;
    border: 2px solid #00B401;
    padding: 20px;
    margin-bottom: 5px;
    border-radius: 20px;
}


@media screen and (max-width: 768px) {
    .member-container{
        flex-direction: column;
        row-gap: 10px;
        margin-left: 20px;

    }
    .member-item{
        padding: 15px;
        margin-bottom: 10px;
    }
}