.content1-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    
  }
  
  .left-side {
    border: 2px solid;
    width: 800px;
    
  }
  .left-side, .right-side {
    border-color: grey;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
   
  }
  
  .right-side {
    height: 360px;
    width: 500px;
    background-color: rgb(25, 25, 143);
    color: #fff;
    text-align: center;

  }
  .contacts-Details {
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    margin-left: 130px;
    font-size: 16px;
  }
  
  .white-icon {
    width: 60px;
    height: 60px;
    color: #fff;
  }
  /* Responsive styles for screens narrower than 768px */
  @media (max-width: 768px) {
    .content1-container {
        flex-direction: column;
        text-align: left;
        padding: 0;
    }
  
    .left-side {
        margin-bottom: 0px;
        width: 100%;
    }
  
    .right-side img {
        display: none;
    }
  
    .right-side {
        width: 100%;
        order: 1;
    }
    .contacts-Details {
        margin-left: 100px;
    }
    .white-icon {
        width: 50px;
    }
  }
  