.gallery-head{
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
}
.highlight{
    color: #00B401;
    margin-right: 10px;
}
.heading{
    font-family: 'poppins', sans-serif;
    margin-top: 2%;
    text-align: center;

}
