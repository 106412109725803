.gallery-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .category-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
    grid-auto-rows: 300px; 
    margin: 20px;
  }
  .category-item {
    text-align: center;
  }
  
  .category-item img {
    width: 100%;
    height: auto;
  }
  
  .category-name {
    margin-top: 10px;
  }
  
  .image-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .image-grid img {
    width: 100%;
    height: auto;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .back-button {
    padding: 10px 20px;
    background-color: #00B401;
    color: #fff;
    font-size: 20px;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  .event-name {
    color: #00B401;
    font-size: 24px;
    font-weight: 200;
    margin-top: 10px;
  }
  .modal img {
    width: 80%;
    height: auto;
  }
  .event-thumbnail {
    margin-bottom: 20px; /* Add some spacing between the event thumbnail and the event name */
  }
  
  .event-thumbnail img {
    width: 200px; /* Adjust the width of the event thumbnail */
    height: auto; /* Maintain the aspect ratio */
    display: block; /* Ensure the image is displayed as a block element */
    margin-bottom: 10px; /* Add some spacing below the event thumbnail */
  }
  .events-categories-thumbnail {
    width: 300px;
    height: 300px;
  }
  .event-thumbnail div {
    font-weight: bold; 
  }
  .category-thumbnail {
  width: 200px; 
  height: auto; 
  display: block; 
  margin-bottom: 10px; 
}
.modal-navigation {
    position: absolute;
    bottom: 20px; 
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px; 
  }
  
  .modal-arrow {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .arrow {
  border: 2px solid white;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: fixed;
  z-index: 999;
}
.arrow:hover {
  background-color: #fff;
  color: #777;
}
.next {
  top: 50%;
  right: 10.5rem;
}
.prev {
  top: 50%;
  left: 10.5rem;
}

.events-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px; 
}

.event-item {
  margin: 10px;
  text-align: center;
}

.events-categories-thumbnail {
  width: 300px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .modal img {
    width: 100%;
    height: auto;
  }
  .next {
    right: 1.5rem;
  }
  .prev {
    left: 1.5rem;
  }
  .events-grid {
    flex-direction: column; 
  }
  
  .event-item {
    width: calc(100% - 20px);
}

  .events-categories-thumbnail {
      width: 100%; 
      height: auto;
  }
  .category-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
    gap: 40px;
    grid-auto-rows: 200px; 
  }
}