@media screen and (max-width: 768px) {

}
.member-button {
    background-color: white;
    font-size: 16px;
    border: 0px;
    border-radius: 7.2px;
    margin-top: 10px;
    padding: 10px;
  }
.member-button:hover{
  color: #00B401;
}
  .knowmorebutton {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    gap: 10px;

  }
  .knowmorebutton:hover{
    cursor: pointer;
    color: #00B401;
  }
  .download-button{
    background-color: white;
    font-size: 12px;
    border: .5px solid #00B401;
    color: #00B401;
    max-width: 150px;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
  }
  .download-button:hover{
    background-color: #00B401;
    color: #fff;
    border: none;
  }