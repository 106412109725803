/* Event Container */
.eventcontainer {
  align-items: center;
  max-width: 100%;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Event Containers */
.event-container {
  display: flex;
  align-items: center;
  border: 1px solid #cccccc;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #fff;
}

.eventleft-side {
  font-weight: 300;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.eventright-side {
  font-weight: 300;
  width: 90%;
  height: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px #ccc;
  border-radius: 0 5px 5px 0;
  flex-direction: column; /* Adjust the layout to a column */
  align-items: center; /* Center the content horizontally */
}
.eventright-side img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.eventleft-side {
  flex: 0.5;
}
.eventright-side {
  flex: 1.5;
}
/* Form Styles */
form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

label {
  font-weight: bold;
}

input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* "Add Event" Button */
button {
  width: 200px;
  background-color: lightgray;
  color: #000;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Read More Link */
.read-more {
  margin-left: 10px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.read-more:hover {
  text-decoration: underline;
}
.button {
  margin: 10px auto; /* Center the button horizontally with some space */
  padding: 10px 20px;
}

.navigation {
  display: flex;
  justify-content: center; /* Center the navigation numbers horizontally */
  margin-top: 20px; /* Add space above the navigation numbers */
}
.event-navigation-controls button {
  width: 40px;
}
.event-info-container {
  background-color: #fff;
  width: 100%;
  border-radius: 7.2px;
  margin: 10px 0;
  align-items: center;
  border: 1px solid #cccccc;
}
.event-info-container img {
  width: 24px;
  height: 24px;
  margin: 5px;
}
.event-info {
  align-items: center;
  border-radius: 7.2px;
  border: 1px solid #00000079;
}
.event-info {
  flex: 0.9;
}
.event-date {
  background-color: #cccccc93;
  border-top-left-radius: 7.2px;
  border-top-right-radius: 7.2px;
  font-size: 12px;
  padding: 5px;
}
.event-button {
  padding: 10px 20px;
  background-color: #00B401;
  color: #fff;
  font-size: 20px;
  border: none;
  cursor: pointer;
  width: 100%;
}
#event-search-button {
  width: 200px;
}
/* Style the page numbers with borders */
.page-number {
  background-color: #ccc;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 7.2px;
  margin: 0 5px;
  cursor: pointer;
}
.event-navigation-controls {
  margin-top: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
}
/* Style the active page number differently */
.page-number.active {
  background-color: #00B401;
  color: #fff;
}
.search-container, .input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adds space below each input field */
}
.search-container button {
  margin-left: 40px;
}
.search-container img {
  width: 20px; /* Adjust the width as needed */
  height: auto;
  margin-right: 10px; /* Add spacing between the image and input */
  margin-left: 10px;
}

.input-container input {
  /* Add styles for input fields */
  width: 100%;
}

@media (max-width: 768px) {
  #event-search-button {
  width: 200px;
  }
  .event-container{
    flex-direction: column;
  }
  .event-info-container {
    width: 100%;
  }
  .event-container {
    padding: 0px;
    margin-top: 20px;
  }
  .eventright-side {
    width: 90%;
  }
  .eventleft-side {
    width: 80%;
  }
  .search-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 10px; /* Adds space below each input field */
  }
}
