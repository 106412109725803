.learnmorebutton {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  gap: 5px;

}

.bg-image {
  background-image: url("../images/thebanner.jpg");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 90vh;

}

.text-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 0 auto;
}

.text-overlay h1 {
  font-size: 5rem;
  margin-bottom: 20px;
}

.text-overlay p {
  font-size: 2rem;
}

/* Responsive styles for smaller screens*/
@media screen and (max-width: 768px) {
  .text-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    align-items: flex-start;
    margin-left: 20px;
  }

  .text-overlay h1 {
    font-size: 2rem;
  }

  .text-overlay p {
    font-size: 1.5rem;
  }

  .learnmorebutton {
    font-size: 1.5rem;
  }

  .bg-image {
    height: 50vh;
  }
}