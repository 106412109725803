/* contactForm.css */

.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
    width: 800px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    margin: 0 auto;
    
  }
  .contact-form-container h1 {
    font-weight: bold;
  }
  .contact-form-container .form-group {
    
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .form-group textarea {
    height:200px;
  }
  .contact-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .contact-form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form-container .name-container {
    display: flex;

  }
  
  .contact-form-container label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .contact-form-container input[type="text"],
  .contact-form-container input[type="email"],
  .contact-form-container textarea {
    padding: 10px;
    width: 600px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
  }
  .contact-form-container textarea {
    width: 600px;
  }
  .contact-form-container input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .contact-form-container button {
    background-color:  #00B401;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;

  }
  
  .contact-form-container button:hover {
    background-color:  rgb(27, 27, 212);
  }
  @media (max-width: 600px) {
  .contact-form-container {
    width: 100%;
    padding: 15px;
    margin: none;
    align-items: flex-start;
  }

  .contact-form-container label {
    font-size: 14px;
  }

  .contact-form-container input[type="text"],
  .contact-form-container input[type="email"],
  .contact-form-container textarea {
    width: 100%;
    padding: 8px;
    font-size: 14px;
  }

  .contact-form-container button {
    padding: 8px 16px;
    font-size: 14px;
    margin: none;
  }
  .contact-form-container .form-group {
    margin-right: 10px; /* Add margin to space the input fields */
  }
}