*{
    margin: 0;
    box-sizing: border-box;
}
.ah{
    font-family: 'Inter', sans-serif;
    text-align: center;
    
}

.about-head{
    text-align: center;
    padding-top: 2%;
    color: #00B401; /* Replace with your desired color */
}
.highlight{
    background-color: #00B401;
    width: 25px;
    height: 4px;
    margin-right: 10px;
}
@media screen and (max-width:768px){
    .ah {
            font-family: 'Inter', sans-serif;
            text-align: start;
            margin-left: 20px;
    
        }
    .about-head{
        text-align: start;
            margin-left: 20px;
            padding-top: 2%;
            color: #00B401;
            /* Replace with your desired color */
    }
    
}