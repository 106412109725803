/* Home.css */
@import url('https://fonts.cdnfonts.com/css/Inter');
.highlight{
    color: #00B401;
    margin-right: 10px;
}
h2{
    font-family: 'poppins', sans-serif;
    font-size: 3rem;
}

.the-head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}
.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  
img {
    height: 70px;
    width:  250px
}
.head{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 2rem;
}

/* Responsive styles for smaller screens*/
@media screen and (max-width: 768px) {
    .the-head{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    }
    .head{
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
    }
    h2{
        font-size: 2rem;
    }
}
.colored-text {
    margin-top:2%;
    margin-bottom: 3%;
    color: #00B401; /* Replace with your desired color */
}


