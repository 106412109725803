
.all-row-container{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
}
.item-row{
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}
.item-img{
    width: 32px;
    height: 32px;
    color: #00B401;
}
.item p{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 24px;
}
.item{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05),0 6px 20px 0 rgba(0, 0, 0, 0.09);
    border: 1px solid white;
    border-radius: 10px;
    width: 250px;
    height: 150px;
    padding: 15px;
    

    display: flex;
    flex-direction: column;
    align-items: center;
}
.item:hover{
    border: 1px solid #00B401;
    transform: translate(5px,-5px);
}

@media screen and (max-width: 768px){
    .item:hover{
        border: 1px solid #00B401;
        transform: translate(2px,-2px);
    }
    .item{
        
        width: 175px;
        height: 100px;
        padding: 5px;
    }
    .item p{
        font-size: 16px;
    }
    .all-row-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;
    }
    img{
        width: 24px;
        height: 24px;
    }
    .item-row{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }
}